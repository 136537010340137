import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useIsVisible, useBlackWhiteOnScroll } from '../../hooks';
import ExpandableList from 'components/ExpandableList';
import constants from 'styles/constants';

const StyledMedia = styled.div`
    margin: 0 auto;
    max-width: 956px;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 0 0 0;
        list-style: none;
        padding: 0;

        > li {
            position: relative;
            flex: 0 0 100%;
            margin-bottom: 45px;

            @media (min-width: ${constants.breakpoint.md}px) {
                flex: 0 0 calc(33.33% - 30px);
            }

            > .media {
                position: relative;
                max-width: 208px;
                width: 100%;
                padding-bottom: 60%;

                > .image {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left bottom;
                }

                > .cover {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 85%;
                    padding: 18px;
                    color: var(--colorLightBlue);
                    border: 1px solid currentColor;
                }
            }

            h4 {
                font-size: 1.1875rem;
                line-height: 1.57;
                margin-top: 30px;
                text-transform: uppercase;
            }

            p > a {
                position: relative;
                z-index: 11;
            }

            @media (hover: hover) {
                &:hover {

                    h4 {
                        color: var(--colorLightBlue); 
                    }
                }
            }
        }
    }

    > div {
        margin: 0 -30px;
        padding: 0 30px;
    }
`

const Item = ({ data }) => {
    const ref = useRef();
    const visible = useIsVisible({ ref })
    const bw = useBlackWhiteOnScroll({ ref, visible })

    let image

    if (data.image) {
        let orientation = data.image.height / data.image.width > 0.8949 ? 'landscape' : 'portrait'

        image = <div style={{ backgroundImage: `url(${data.image.path})`, filter: `grayscale(${bw}%)` }} className={'image filter ' + orientation} />

    } else {

        image = <div className="cover">{data.cover_text}</div>
    }


    //console.log(data.image)

    

    return (
        <li>
            {(data.type === 'link') 
                ? <a href={data.link} target="_blank" className="area" />
                : <Link to={data.path} className="area" /> 
            }
            <div className="media" ref={ref}>{image}
                {data.image?.caption &&
                    <p className="copy">{data.image.caption}</p>
                }
            </div>
            <h4 dangerouslySetInnerHTML={{ __html: data.title }}></h4>
            <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
        </li>
    )
}

const Media = ({ data }) => {
    return (
        <StyledMedia>
            <ul>
                {data.slice(0, 3).map((item, index) => (
                    <Item data={item} key={index} />
                ))}
            </ul>
            {(data.length > 3) &&
                <ExpandableList
                    more="mehr Medien"
                    less="weniger Medien">
                    {data.slice(3).map((item, index) => (
                        <Item data={item} key={index} />
                    ))}
                </ExpandableList>
            }
        </StyledMedia>
    );
};

export default Media;