import { useState, useEffect, useRef } from 'react'

const usePreload = ({ image, shouldLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        if (shouldLoad && !isLoaded) {
            var i = new Image();

            i.onload = () => {
                setIsLoaded(true)
            };

            i.src = image;
        }

    }, [shouldLoad]);

    return isLoaded
}

const useIsVisible = ({ ref }) => {
    const [visible, setVisisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log(entry);

                if (entry.isIntersecting) {
                    console.log('visible')
                    setVisisible(true)
                } else {
                    console.log('not visible')
                    setVisisible(false)
                }
            },
            {
                root: null,
                rootMargin: "300px"
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
    }, [ref]);


    return visible
}

const useIsMounted = () => {
    const isMountRef = useRef(false);
    useEffect(() => {
        isMountRef.current = true;
    }, []);
    return isMountRef.current;
};

const useExpand = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef()
    const height = isExpanded && ref.current ? `${ref.current.clientHeight}px` : 0

    const toggleExpand = () => {
        console.log('toggleExpand')
        setIsExpanded(!isExpanded)
    }

    return {
        ref,
        height,
        isExpanded,
        toggleExpand
    }
}

const useBlackWhiteOnScroll = ({ ref, visible }) => {
    const [bw, setBw] = useState(20)

    const scroll = () => {
        const y = ref.current.getBoundingClientRect().y + window.scrollY;
        const center = y + ref.current.clientHeight / 2;
        const scrollCenter = window.scrollY + window.innerHeight / 2;

        let test = Math.abs(center - scrollCenter)

        //console.log('top: ' + y, 'height: ' + ref.current.clientHeight)

        //console.log(center, scrollCenter);
        //console.log(window.innerHeight-ref.current.clientHeight)
        //console.log(test)

        //test /= ((window.innerHeight-ref.current.clientHeight)/100);
        //test /= 6;
        test /= window.innerHeight/200;


        if (test < 20) test = 0

        if (y <= 680 && center > scrollCenter) {
            test = 0
        }

        setBw(test)

    }

    useEffect(() => {
        if (visible) {
            window.addEventListener('scroll', scroll)
            scroll();
        }

        return () => {
            window.removeEventListener('scroll', scroll)
        }

    }, [visible, ref])

    return bw
}

export {
    usePreload,
    useIsVisible,
    useIsMounted,
    useExpand,
    useBlackWhiteOnScroll
}