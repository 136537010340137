import { createGlobalStyle } from 'styled-components'
import constants from './constants';

const Index = createGlobalStyle`
    :root {
        --paddingPageX: 24px;
        --paddingContentX: 96px;
        --colorLightBlue: ${constants.colors.lightblue};
        --colorLightBlue2: ${constants.colors.lightblue2};
        word-wrap: break-word;
        hyphens: auto;

        @media (min-width: ${constants.breakpoint.xs}px) {
            --paddingPageX: 30px;
        }

        @media (min-width: ${constants.breakpoint.lg}px) {
            --paddingPageX: 2.7%;
            --paddingContentX: 7%;
        }
    }

    body {
        font-family: 'Palino';
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5;
        overflow-y: scroll;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-weight: inherit;
        font-size: inherit;
    }

    h3 {
        font-size: 1.6875rem;
        line-height: 1.111;
        text-transform: uppercase;
    }

    a {
        text-decoration: none;
        color: #000;
    }

    p {
        margin: 0;
    }

    p + p {
        margin-top: 1.875rem;
    }

    p a {
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 22%, var(--colorLightBlue) 22.01%, var(--colorLightBlue) 50%, var(--colorLightBlue) 62%, rgba(255,255,255,0) 62.01%);
        
        &:hover {
            background: none;
            color: var(--colorLightBlue);
        }
    }

    #app {
        overflow: hidden;
    }

    main {
        width: 100%;
        padding: 260px var(--paddingPageX) 0 var(--paddingPageX);

        @media (min-width: ${constants.breakpoint.xs}px) {
            padding-top: 300px;
        }

        @media (min-width: ${constants.breakpoint.sm}px) {
            padding-top: 400px;
        }
    }

    .filter {
        -webkit-transform: translateZ(0);
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
    }

    p.copy {
        position: absolute;
        width: 100%;
        left: -6px;
        bottom: 0;
        transform: rotate(-90deg);
        transform-origin: left bottom;
        font-size: 0.5625rem;
        text-transform: uppercase;
        line-height: 1;
    }

    a.area {
        display: block;
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`

export default Index;