import { memo, useEffect, createRef, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom';
import GlobalStyles from './styles';
import Header from './components/header'
import Section from './components/section';
import Text from './components/text';
import News from './components/news';
import Gallery from './components/gallery';
import Theatre from './components/theatre';
import Prose from './components/prose';
import Essay from './components/essay';
import Media from './components/media';
import Overlay from './components/overlay';
import { getPage } from './actions';

//import data from './data';

const components = {
    'gallery': Gallery,
    'theatre': Theatre,
    'text': Text,
    'news': News,
    'prose': Prose,
    'essay': Essay,
    'media': Media
}

function App({ data, getPage, location, history, match }) {
    const itemRefs = useRef([]);
    const [overlay, setOverlay] = useState({
        type: null,
        active: false,
        visible: false,
        scrollY: 0
    })

    if (itemRefs.current.length !== data.length) {
        console.log('create refs')
        itemRefs.current = Array(data.length).fill().map((_, i) => itemRefs.current[i] || createRef());
    }

    const { main, second } = match.params;

    useEffect(() => {
        getPage('/')
    }, [])

    useEffect(() => {
        if (second && !overlay.active) {
            setOverlay({
                type: main==='medien' ? 'media' : 'page',
                main: main,
                active: true,
                visible: true,
                scrollY: window.scrollY || window.pageYOffset
            })
        }

        if (!second && overlay.active) {
            
            let scrollY = overlay.scrollY

            if(main !== overlay.main) {

           
                const index = data.findIndex(item => item.path === `/${main}`)
                const ref = itemRefs.current[index]

                if (ref) {
                    scrollY = ref.current.offsetTop
                }   

            }
            
            
            setOverlay(prev => ({
                ...prev,
                active: false,
                scrollY: scrollY
            }))

            setTimeout(() => {
                setOverlay(prev => ({
                    ...prev,
                    visible: false
                }))

                window.scroll(0, scrollY)
            }, 300);
        }
    }, [main, second, overlay])

    useEffect(() => {
        const scroll = () => {
            if (!second && !overlay.visible && !overlay.active) {

                if (window.scrollY < 600) {
                    
                    if(main) history.replace('/');
                } else {
                    let current = null;

                    console.log(itemRefs)

                    itemRefs.current.forEach((ref, index) => {

                        //console.log(ref.current)

                        //console.log(ref.current.offsetTop - 600, window.scrollY)

                        if (ref.current.offsetTop - 600 < window.scrollY) {
                            current = index
                        }
                    })


                    console.log('-----------')
                    console.log(current)

                    if (current !== null) {

                        //console.log(data[current])

                        const dataTemp = data[current]

                        if(dataTemp.path !== location.pathname) {
                            history.replace(dataTemp.path);
                        }
                    
                    } else {
                        if(main) history.replace('/');
                    }
                }
            }
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [data, location, second, overlay, main])


    const scrollTo = (path) => {
        const index = data.findIndex(item => item.path === path)
        const ref = itemRefs.current[index]

        /*
        console.log('------------TEST')
        data.forEach((item, index) => {
            console.log(item.path + itemRefs.current[index].current.offsetTop)
        })
        */

        if (ref) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: "smooth"
            });
        }
    }

    let style = overlay.visible 
                ? { position: 'fixed', top: `-${overlay.scrollY}px` }
                : {}

    return (
        <>
            <GlobalStyles />
            <Header
                scrollTo={path => scrollTo(path)} data={data}
            />
            <main style={style}>
                {data.map((item, index) => {

                    const Content = components[item.component];
                    const title = index<data.length-1 ? item.title : null

                    return (
                        <Section path={item.path} ref={itemRefs.current[index]} title={title} key={index}>
                            {item.component &&
                                <Content data={item.data} />
                            }
                        </Section>
                    )
                })}
            </main>
            <Overlay
                type={overlay.type}
                active={overlay.active}
                visible={overlay.visible}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    const { pages } = state.pages
    const page = pages.find(page => page.path === '/')
    const data = page ? page.data : []

    return {
        data
    }
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path))
})

function propsAreEqual(prevProps, nextProps) {
    return (
        prevProps.location.pathname === nextProps.location.pathname
        && prevProps.data === nextProps.data
    )
}

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(memo(App, propsAreEqual));

