import React from 'react';
import Gallery from '../gallery2';
import styled, {keyframes} from 'styled-components';
import { useEffect } from 'react';
import { NavLink , Link} from 'react-router-dom';
import constants from 'styles/constants';

const fadeIn = keyframes`
    0%  {
        opacity: 0;
        transform: scale(0.95);
    }
   
    100% {
        opacity: 1;
        transform: scale(1);
    }
`

const StyledContent = styled.div`
    padding: 0 0 70px 0;
    animation: ${fadeIn} 1s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
    transform-origin: center 300px;
    
    &::before {
        display: block;
        content: '${props => props.before}';
        font-size: 2rem;
        line-height: 1;
        padding: 18px 0 18px 0;
        visibility: hidden;

        @media (min-width: ${constants.breakpoint.xs}px) {
            font-size: 2.5rem;
        }

        @media (min-width: ${constants.breakpoint.sm}px) {
            font-size: 3.125rem;
        }
    }

    .text {
        max-width: 940px;
        margin: 0 auto;

        .col2s div + div {
            margin-top: 1.875rem;
        }
        
        @media (min-width: ${constants.breakpoint.sm}px) {
            .col2s {
                display: flex;
                justify-content: space-between;

                > div {
                    flex: 0 0 calc(50% - 15px);
                }  
                
                div + div {
                    margin-top: 0;
                }
            }
        } 

        .small {
          font-size: 0.9375rem;
        }

        &.info {
            border: 6px solid var(--colorLightBlue);
            font-family: 'DroidSansMono';
            font-weight: 400;
            margin-bottom: 40px;
            font-size: 0.9375rem;
            line-height: 1.333;
            padding: 2%;

            a {
                background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 32%, var(--colorLightBlue) 32.01%, var(--colorLightBlue) 50%, var(--colorLightBlue) 71%, rgba(255,255,255,0) 71.01%);
            }

        }

        &.example {
            max-width: 744px;
            margin-top: 30px;

            p + p {
                margin-top: 0;
                text-indent: 30px;
            }

            h2 {
                margin-bottom: 2.5rem;
            }

            h3 {
                margin-top: 5rem;
                margin-bottom: 1.25rem;
                font-size: 1.25rem;
            }

            h4 {
                margin-top: 5rem;
                margin-bottom: 1.25rem;
                font-style: italic;
            }

            h3 + h4 {
                margin-top: 1.25rem;
            }

            blockquote {
                margin-top: 5rem;
                margin-bottom: 3.75rem;
                font-style: italic;
            }
        }
    }

    .video {
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .navigation {
        display: flex;
        justify-content: flex-start;
        color: var(--colorLightBlue);
        margin-bottom: 15px;
        font-size: 0.8rem;

        
        @media (min-width: ${constants.breakpoint.sm}px) {
            font-size: inherit;
        }

        > * {
            flex: 0 0 auto;
        }

        a {
            padding: 0 4px;
            color: var(--colorLightBlue);

            @media (min-width: ${constants.breakpoint.sm}px) {
                padding: 0 8px;
            }

            &:hover,
            &.active {
                color: #000;
            }

            .large {
                display: none;

                @media (min-width: ${constants.breakpoint.md}px) {
                    display: inline-block;
                }
            }
        }

        .right {
            margin-left: auto;
        }
    }
`

const Content = ({data, match}) => {

    const { main, second, third } = match.params;

    useEffect(()=> {

        //window.scroll(0, 0)

    }, [])

    let idVideo

    if(third==='video' && data.video_url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = data.video_url.match(regExp);
        
        if (match && match[2].length == 11) {
            idVideo= match[2];
        } else {
        
        }
    }



    const navigation = [<Link to={`/${main}`}>{'zurück'}<span className="large">&nbsp;{'zu ' + (main === 'prosa' ? 'Prosa' : 'Theater')}</span></Link>]

    if(data.reading_sample || data.video_url) {
        if(data.reading_sample) {
            navigation.push(<> / </>)
            navigation.push(<NavLink to={`/${main}/${second}`} isActive={() => !third}>Übersicht</NavLink>)
        }
    }

    if(data.reading_sample) {
        navigation.push(<> / </>)
        navigation.push(<NavLink to={`/${main}/${second}/leseprobe`} isActive={() => third==='leseprobe'}>Leseprobe</NavLink>)
    }

    if(data.video_url) {
        navigation.push(<> / </>)
        navigation.push(<NavLink to={`/${main}/${second}/video`} isActive={() => third==='video'}>Video</NavLink>)
    }

    if(data.publishing_house) {
        navigation.push(<a className="right" href={data.publishing_house} target="_blank">Verlag</a>)
    }

    let title = 'Hannah Dübgen, ';

    if(main=='prosa') {
        title += 'Prosa: '
    } else if(main=='theater') {
        title += 'Theater: '
    }

    title += data.title + '|'

    return (
        <StyledContent before={title}>
            
            <div className="navigation">
                {'{'}
                {navigation}
                {'}'}
            </div>
            {!third && 
                <>
                    {data.images && 
                        <Gallery data={data.images} multi />
                    }
                    {data.info &&
                        <div className="text info">
                            <div dangerouslySetInnerHTML={{__html: data.info}} />
                        </div>
                    }
                    {data.text && 
                        <div className="text" dangerouslySetInnerHTML={{__html: data.text}}></div>
                    }
                </>
            }
            {third==='leseprobe' &&
                <div className="text example" dangerouslySetInnerHTML={{__html: data.reading_sample}}></div>
            }
            {third==='video' && 
                <div className="video">
                    <iframe id="ytplayer" type="text/html" width="640" height="360"
src={`https://www.youtube.com/embed/${idVideo}?autoplay=1`}
frameborder="0"/>
                </div>
            }
        </StyledContent>
    );
};

export default Content;