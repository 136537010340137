import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { usePreload, useIsVisible } from '../../hooks'
import constants from 'styles/constants';

const StyledGallery = styled.div`
    margin: 0 auto 70px auto;

    > div.images {
        position: relative;
        padding-bottom: 72.03%;

        @media (min-width: ${constants.breakpoint.md}px) {
            padding-bottom: 54.38%;
        }

        > div {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            > div {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: all 1.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                &.next {
                    opacity: 0;     
                    filter: blur(30px);
                }

                &.active {
                    opacity: 1;
                    transform: scale(1);
                    filter: blur(0);

                    p.copy {
                        opacity: 1;
                    }
                }

                > div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center center;
                    opacity: 0;
                    transition: opacity 1.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                    &.loaded {
                        opacity: 1;
                    }
                }
            }   
        }

        p.copy {
            opacity: 0;

            &.active {
                opacity: 1;
            }
        }
    }
`

const Image = ({ data, className }) => {
    const isLoaded = usePreload({ image: data.path, shouldLoad: 1 })
    const backgroundImage = `
        linear-gradient(
            rgba(255,255,255,0.3),
            rgba(255,255,255,0.3)
        ),
        linear-gradient(
            ${data.color},
            ${data.color}
        )`;

    return (
        <div
            className={className}
            style={{ backgroundImage }}>
            <div 
                className={isLoaded ? 'loaded' : ''} 
                style={{ backgroundImage: `url(${data.path})` }} />
        </div>
    )
}

const Gallery = ({data}) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const interval = useRef();
    const ref = useRef();
    const visible = useIsVisible({ ref })

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        if (data.length > 1) {
            interval.current = setInterval(() => {
                setCurrentSlide(prev => prev + 1)
            }, 8000);
        }

        return () => clearInterval(interval.current);
    }, [])

    useEffect(() => {

        const scroll = () => {
            const y = ref.current ? ref.current.getBoundingClientRect().y + window.scrollY : 0;

            const center = ref.current ? y + ref.current.clientHeight / 2 : 0;

            const scrollCenter = window.scrollY + window.innerHeight / 2;

            const test = Math.abs(center - scrollCenter)

            //console.log('top: ' + y, 'height: ' + ref.current.clientHeight)

            //console.log(center, scrollCenter);
            //console.log(test)

            const test2 = test / 6;

            setScrollY(test2)

        }

        if (visible) {
            window.addEventListener('scroll', scroll)
        }

        return () => {
            window.removeEventListener('scroll', scroll)
        }

    }, [visible, ref])

    const getImage = (slide) => {
        let slideNew = slide % data.length;
        return slideNew < 0 ? data.length + slideNew : slideNew
    }

    const slides = []
    const copyrights = []

    for (let i = currentSlide - 1; i <= currentSlide + 1; i++) {
        const item = data[getImage(i)]
        const className = i === currentSlide
            ? 'active' : i === currentSlide + 1
                ? 'next' : ''

        slides.push(
            <Image
                key={i}
                data={item}
                className={className}
            />
        )
        
        if(item.description) {
            copyrights.push(
                <p className={'copy ' + className}>{item.description}</p>
            )
        }
    }

    let style = { filter: `grayscale(20%)` }

    //filter: grayscale(100%);

    return (
        <StyledGallery ref={ref} style={style}>
            <div className="images">
                <div>
                    {slides}
                </div>
                {copyrights}
            </div> 
        </StyledGallery>
    );
};

export default Gallery;