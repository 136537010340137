import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const StyledMedia = styled.div`

    .image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .video {
        position: absolute;
        height: 100%;
        width: calc(100vh * 1.778);
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @media (max-aspect-ratio: 16/9) {
            width: 100%;
            height: auto;
            top: 50%;
            left: 0;
            padding-bottom: 56.25%;
            transform: translateY(-50%);
        }

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .close {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        font-size: 3.125rem;
        color: #fff;
        left: var(--paddingPageX);
    }
`

const Media = ({data}) => {
    const history = useHistory();

    let idVideo

    if(data.video_url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = data.video_url.match(regExp);
        
        if (match && match[2].length == 11) {
            idVideo= match[2];
        } else {
        
        }
    }

    return (
        <StyledMedia>
            <span className="close" onClick={() => history.goBack()}>X</span>
            {(data.type==='image') &&
               <div 
                    className="image" 
                    style={{backgroundImage: `url(${data.image.path})`}}
               />
            }
            {(data.type==='video') &&
                <div 
                    className="video" 
                >
                     <iframe id="ytplayer" type="text/html" width="640" height="360"
src={`https://www.youtube.com/embed/${idVideo}?autoplay=1`}
frameborder="0"/>
                </div>
            }
        </StyledMedia>
    );
};

export default Media;