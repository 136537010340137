import React from 'react';
import { useExpand } from '../../hooks';
import styled from 'styled-components';
import Button from 'components/button';

const StyledExpandableList = styled.div`
    height: 0;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    will-change: height;
`

const ExpandableList = (props) => {
    const { ref, isExpanded, height, toggleExpand } = useExpand();

    const defaults = {
        as: 'ul',
        more: 'mehr',
        less: 'weniger'
    }

    const options = { ...defaults, ...props }

    console.log(options)

    console.log('expandable list');

    return (
        <>
            <StyledExpandableList style={{ height }}>
                <options.as ref={ref}>
                    {props.children}
                </options.as>
            </StyledExpandableList>
            <Button
                onClick={toggleExpand}
                text={!isExpanded ? options.more : options.less}
            />
        </>
    );
}

export default ExpandableList;