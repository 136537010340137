import React, { useRef } from 'react';
import { useIsVisible, useBlackWhiteOnScroll } from '../../hooks';
import ExpandableList from '../ExpandableList';

const Item = ({ data }) => {
    const ref = useRef();
    const visible = useIsVisible({ ref })
    const bw = useBlackWhiteOnScroll({ ref, visible })

    let image

    if (data.image) {
        let orientation = data.image.height / data.image.width > 0.8949 ? 'landscape' : 'portrait'

        image = <div style={{ backgroundImage: `url(${data.image.path})`, filter: `grayscale(${bw}%)` }} className={'image filter ' + orientation} />

    } else {

        image = <div className="cover">{data.cover_text}</div>
    }


    //console.log(data.image)


    return (
        <li>
            <div className="media" ref={ref}>
                <div>
                    {image}
                    {data.image?.caption &&
                        <p className="copy">{data.image.caption}</p>
                    }
                </div>
            </div>
            <h4 dangerouslySetInnerHTML={{ __html: data.title }}></h4>
            <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
        </li>
    )
}

const Column = ({ data, title }) => {

    return (
        <div>
            <h3>{title}</h3>
            <ul>
                {data.slice(0, 1).map((item, index) => (
                    <Item data={item} key={index} />
                ))}
            </ul>
            {(data.length > 1) &&
                <ExpandableList>
                    {data.slice(1).map((item, index) => (
                        <Item data={item} key={index} />
                    ))}
                </ExpandableList>
            }
        </div>
    );
};

export default Column;