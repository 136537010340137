import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useIsVisible, useBlackWhiteOnScroll } from 'hooks'
import constants from 'styles/constants';
import ExpandableList from 'components/ExpandableList';

const StyledTheatre = styled.div`
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style: none;
        
        > li {
            position: relative;
            flex: 0 0 100%;
            margin-bottom: 45px;

            @media (min-width: ${constants.breakpoint.sm}px) {
                flex: 0 0 calc(50% - 15px);
            }

            > div {
                position: relative;
                padding-bottom: 89.49%;

                > .image {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    height: 100%;
                    background: no-repeat center bottom;
                    background-size: contain;
                    transform: translateX(-50%)
                }
            }

            h3 {
                margin: 22px auto 0;
            }

            @media (hover: hover) {
                &:hover {
                    h3 {
                        color: var(--colorLightBlue);
                    }

                    .image {
                       opacity: 0.95;
                    }
                }
            }
        }
    }
`

// 457 // 409


const Item = ({ data }) => {
    const ref = useRef();
    const visible = useIsVisible({ ref })
    const bw = useBlackWhiteOnScroll({ ref, visible })

    let item = data

    const image = item.image;
    let orientation = image.height / image.width > 0.8949 ? 'landscape' : 'portrait'
    let width = 0.8949 / (image.height / image.width) * 100
    if (width > 100) width = 100

    return (
        <li>
            <Link to={item.path} className="area" />
            <div>
                <div className="image filter" ref={ref} style={{ backgroundImage: `url(${image.path})`, width: `${width}%`, filter: `grayscale(${bw}%)` }}>
                    {image.caption &&
                        <p className="copy">{image.caption}</p>
                    }
                </div>
            </div>
            <h3 style={{ width: `${width}%` }} dangerouslySetInnerHTML={{ __html: item.title }}></h3>
        </li>
    )
}

const Prose = ({ data }) => {
    return (
        <StyledTheatre>
            <ul>
                {data.slice(0, 2).map((item, index) => (
                    <Item data={item} key={index} />
                ))}
            </ul>
            {(data.length > 2) &&
                <ExpandableList
                    more="mehr Prosa"
                    less="weniger Prosa">
                    {data.slice(2).map((item, index) => (
                        <Item data={item} key={index} />
                    ))}
                </ExpandableList>
            }
        </StyledTheatre>
    );
};

export default Prose;