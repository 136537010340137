import { pageConstants } from '../constants';

const getPage = path => ({
    type: pageConstants.GETPAGE_REQUEST,
    path,
});

export {
    getPage
}
