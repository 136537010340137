import React, { forwardRef } from 'react';
import styled from 'styled-components';
import constants from 'styles/constants';

const StyledSection = styled.section`
    position: relative;
    padding: 90px 0;
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;

    @media (min-width: ${constants.breakpoint.lg}px) {
        padding: 90px var(--paddingContentX);
    }

    & + & { 
        border-top: 1px solid var(--colorLightBlue2);
    }

    > h2 {
        color: var(--colorLightBlue);
        font-size: 19px;
        width: calc(100% - 20px);
        max-width: 173px;
        margin-bottom: 30px;

        br {
            display: none;
        }

        @media (min-width: ${constants.breakpoint.lg}px) {
            position: absolute;
            left: 0;
            top: 0;
            padding-top: 90px;

            br {
                display: inline-block;
            }
        }
    }
`

const Section = forwardRef((props, ref) => {
    return (
        <StyledSection ref={ref}>
            {props.title && 
                <h2 dangerouslySetInnerHTML={{ __html: props.title.replaceAll(' ', ' <br />') }} />
            }   
            {props.children}
        </StyledSection>
    );
});

export default Section;