import React, {
    useEffect,
    memo,
    useRef,
    useState
} from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useIsMounted } from 'hooks';
import { connect } from 'react-redux'
import { compose } from 'redux'
import constants from 'styles/constants';

const blink = keyframes`
    0%  {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    50.01% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
`

const StyledHeader = styled.div`
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;  
    font-size: 2rem;
    line-height: 1;
    color: var(--colorLightBlue);
    hyphens: none;

    @media (min-width: ${constants.breakpoint.xs}px) {
        font-size: 2.5rem;
    }

    @media (min-width: ${constants.breakpoint.sm}px) {
        font-size: 3.125rem;
    }

    > div {
        padding: 18px var(--paddingPageX) 18px var(--paddingPageX);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAEsCAYAAADpZ2LWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDQ2MCwgMjAyMC8wNS8xMi0xNjowNDoxNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjIgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDBBOEMwNkZFQzkzMTFFQkFENUVCNDVENzhFQ0JBOTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDBBOEMwNzBFQzkzMTFFQkFENUVCNDVENzhFQ0JBOTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMEE4QzA2REVDOTMxMUVCQUQ1RUI0NUQ3OEVDQkE5OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMEE4QzA2RUVDOTMxMUVCQUQ1RUI0NUQ3OEVDQkE5OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvcY+2oAAAB6SURBVHjazJDRCsAgCEXb2P9/zD6wux4iGMRS0+uEINBzj3gAuEurqz3MP2fp9WrVBbWJzykvfJUjxQmHgjdeM6SIv7MeZ9ol1MfMptRrZ1WOFx5HqTZUBapybFKbi4DHUV53lthzpXGr2oZTWv/HCYEpFDNwfB4BBgCXdbKtMZWMFQAAAABJRU5ErkJggg==);
        background-size: 100% 100%;
    }

    navigation {
        display: block;
        padding: 0 var(--paddingPageX) 0 var(--paddingPageX);
        margin-top: -18px;

        a:hover,
        a.active {
            color: var(--colorLightBlue);
        }
    }

    h1 {
        display: inline-block;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                color: #000;
            }
        }
    }

   

    .menu {
        display: inline-block;
        cursor: pointer;
        color: #000;

        @media (hover: hover) {
            &:hover {
                color: var(--colorLightBlue); 
            }
        }

    }



    .current {
        color: var(--colorLightBlue);
    }

    &::after {
        position: fixed;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0;
        content: '';
        transition: opacity 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        pointer-events: none;
    }

    &.active::after {
        opacity: 0.8;
    }

    .cursor.finished {
        animation: ${blink} 1.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) infinite;
    }
`

const title = [
    {
        tag: 'h1',
        text: 'Hannah Dübgen',
    }
]

const navigation = [
    {
        tag: 'NavLink',
        text: 'Vita',
        path: '/vita'
    },
    {
        tag: 'br',
    },
    {
        tag: 'NavLink',
        text: 'Aktuell',
        path: '/aktuell'
    },
    {
        tag: 'br',
    },
    {
        tag: 'NavLink',
        text: 'Prosa',
        path: '/prosa'
    },
    {
        tag: 'br',
    },
    {
        tag: 'NavLink',
        text: 'Theater',
        path: '/theater'
    },
    {
        tag: 'br',
    },
    {
        tag: 'NavLink',
        text: 'Essay',
        path: '/essay'
    },
    {
        tag: 'br',
    },
    {
        tag: 'NavLink',
        text: 'Medien',
        path: '/medien'
    },
    {
        tag: 'br',
    },
    {
        tag: 'NavLink',
        text: 'Kontakt und Partner',
        path: '/kontakt-und-partner'
    },
]


console.log(navigation)

const inititialText = title.concat([

], navigation)

const Header = ({ pageData, location, match, scrollTo, history, data }) => {
    const [status, setStatus] = useState({
        current: {
            word: 0,
            letter: 0,
            direction: 1
        },
        target: {
            word: 0,
            letter: 0,
        },
        textTarget: inititialText,
        textCurrent: inititialText,
        section: null,
        detail: null,
        introLines: null,
        navOpen: false,
        finished: false
    })

    const isMounted = useIsMounted();
    const interval = useRef();
    const menu = useRef();
    const menu2 = useRef();
    const { main, second } = match.params;


    const timeout = useRef();

    //console.log(main, second)

    const handleOutsideClick = (event) => {
		if (menu.current && !menu.current.contains(event.target) && menu2.current && !menu2.current.contains(event.target)) {
			setStatus(prev => ({
                ...prev,
                navOpen: false
            }))
		}
	}

    useEffect(() => {
		document.addEventListener('click', handleOutsideClick)
		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [])

    useEffect(() => {
        if (isMounted) {
            console.log('CHECK LOCATION')
            console.log(main, second)

            let extend = null

            switch (main) {
                case 'vita':
                    extend = 'Vita'
                    break;
                case 'aktuell':
                    extend = 'Aktuell'
                    break;
                case 'prosa':
                    extend = 'Prosa'
                    break;
                case 'theater':
                    extend = 'Theater'
                    break;
                case 'essay':
                    extend = 'Essay'
                    break;
                case 'medien':
                    extend = 'Medien'
                    break;
                case 'kontakt-und-partner':
                    extend = 'Kontakt und Partner'
                    break;
            }

 
            setStatus(prev => ({
                ...prev,
                section: extend,
                detail: second && pageData.title ? pageData.title : null
            }))

            console.log('location update')

        }

    }, [main, second, pageData])

    useEffect(() => {
        const scroll = () => {
            if (isMounted && !main) {
                let lines = null

                if (window.scrollY < 40) {
                    lines = 13
                } else if (window.scrollY < 100) {
                    lines = 11
                } else if (window.scrollY < 150) {
                    lines = 9
                } else if (window.scrollY < 200) {
                    lines = 7
                } else if (window.scrollY < 250) {
                    lines = 5
                } else if (window.scrollY < 300) {
                    lines = 3
                } else if (window.scrollY < 350) {
                    lines = 1
                } else {
                    lines = null
                }

                console.log(window.scrollY)



                console.log(lines)

                setStatus(prev => ({
                    ...prev,
                    introLines: lines,
                    dotes: lines && lines<13 ? true : false 
                }))
            }
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [isMounted, main])


    useEffect(() => {
        console.log('TRY CREATE textTarget')

        if (isMounted) {
            console.log('CREATE textTarget')

            let targetNew = title;

            if (status.section && !status.detail) {

                targetNew = targetNew.concat([
                    {
                        text: ', ',
                    },
                    {
                        tag: 'span',
                        text: status.section,
                        className: 'current'
                    }
                ])
            }

            if (status.section && status.detail) {

                targetNew = targetNew.concat([
                    {
                        text: ', ',
                    },
                    {
                        tag: 'span',
                        text: status.section,
                        className: 'current'
                    },
                    {
                        text: ': ',
                    },
                    {
                        tag: 'a',
                        text: status.detail,
                        className: 'current',
                        path: `/${main}/${second}/`
                    }
                ])
            }

            

            if (status.introLines && !status.navOpen) {

                targetNew = targetNew.concat(navigation.slice(0, status.introLines))

                if(status.dotes) {

                    targetNew = targetNew.concat([
                        {
                            tag: 'span',
                            text: '...',
                            className: 'menu'
                        }
                    ])

                }

            } else if (status.navOpen) {

                targetNew = targetNew.concat(navigation);
            }

            console.log(targetNew)

            setStatus(prev => ({
                ...prev,
                textTarget: targetNew
            }))
        }

    }, [status.section, status.detail, status.introLines, status.navOpen])

    useEffect(() => {
        //if (!intro.current) {

        console.log('UPDATE TARGET')
        console.log(status.textCurrent)
        console.log(status.textTarget)

        let test = 0



        status.textTarget.forEach((word, index) => {
            const wordCompare = status.textCurrent[index];

            if (wordCompare && word.text === wordCompare.text && word.tag === wordCompare.tag && test === index) {
                test++;
            } else if (!wordCompare && test === index) {
                test++;
            }
        })

        //if (test > 1) {
        test--
        //}

        console.log('New target: ', test)
        console.log(test, status.current.word)

        if (test === status.current.word) {
            test = status.textTarget.length - 1
        }

        console.log(status.current)
        console.log(status.target)
        console.log('New target update: ', test)

        if (test < status.current.word) {

            console.log('GO REVERSE')


            setStatus(prev => ({
                ...prev,
                current: {
                    ...prev.current,
                    direction: -1
                },
                target: {
                    ...prev.target,
                    word: test,
                    letter: prev.textCurrent[test].text ? prev.textCurrent[test].text.length - 1 : 0
                }
            }))

        } else {

            console.log(status.textTarget[test])

            setStatus(prev => {
                return {
                    ...prev,
                    current: {
                        ...prev.current,
                        direction: 1
                    },
                    target: {
                        ...prev.target,
                        word: prev.textTarget.length-1,
                        letter: prev.textTarget[test].text ? prev.textTarget[test].text.length - 1 : 0
                    },
                    textCurrent: prev.textTarget
                }
            })
        }

        //const letterTarget
        //}
    }, [status.textTarget])

    useEffect(() => {

        if (status.current.letter !== status.target.letter || status.current.word !== status.target.word || !status.finished) {

            clearTimeout(timeout.current);
            
            let time = Math.random() * (1 - 0.5) + 0.5;

            if (status.navOpen) {
                time *= 10;
            } else {
                time *= 25;
            }


            console.log('----------')
            console.log(status)

            /*
            console.log(status.current.direction, status.section, status.current.word, status.target.word)
            console.log(status.textCurrent[status.current.word])
            */

            if (status.current.direction === 1 && status.current.word === status.target.word && (status.target.letter - status.current.letter < 6)) {
                console.log('slow')
                //time *= 5;

                switch (status.target.letter - status.current.letter) {
                    case 1:
                        time *= 5;
                        break;
                    case 2:
                        time *= 4;
                        break;
                    case 3:
                        time *= 3;
                        break;
                    default:
                        time *= 2;
                        break;
                }
            }

        if (status.current.direction === -1) time /= 3


            timeout.current = setTimeout(() => {

                console.log('ss')

                //console.log(status)

                if (status.current.letter !== status.target.letter || status.current.word !== status.target.word) {
                    //console.log(current)
                    //console.log(target)
                }

                const partCurrent = status.textCurrent[status.current.word]
                const partCurrentBefore = status.current.word > 0 ? status.textCurrent[status.current.word - 1] : null

                let wordNext = null
                let letterNext = null

                let letterNextTest = status.current.direction === -1 && partCurrentBefore && partCurrentBefore.tag !== 'br'
                    ? partCurrentBefore.text.length - 1
                    : 0

                //console.log('-----------------')
                //console.log(partCurrent)

                if (partCurrent && partCurrent.tag === 'br') {

                    wordNext = status.current.word + status.current.direction
                    letterNext = letterNextTest

                    //console.log('STEP 0')

                } else {


                    //console.log(current.word, textCurrent.length - 1, current.letter, partCurrent.text.length - 1)

                    //console.log('STEP 1')

                    if (status.current.direction === 1) {

                        //console.log('STEP 1.1')

                        if ((status.target.word - status.current.word) > 2 && status.current.word > 0) {

                            letterNext = status.current.letter + 2

                            if (letterNext > partCurrent.text.length - 1) {
                                wordNext = status.current.word + 1
                                letterNext = 0
                            }

                        } else if (status.current.word <= status.target.word && status.current.letter < partCurrent.text.length - 1) {

                            //console.log('STEP 1.1.1')

                            letterNext = status.current.letter + 1
                        } else if (status.current.word < status.target.word && status.current.letter === partCurrent.text.length - 1) {

                            //console.log('STEP 1.1.2')

                            wordNext = status.current.word + 1
                            letterNext = 0
                        }
                    } else {

                        if (status.current.word > status.target.word && status.current.letter === 0) {
                            wordNext = status.current.word - 1
                            letterNext = letterNextTest
                        } else if (status.current.word > status.target.word && status.current.letter > 0) {
                            letterNext = status.current.letter - 1
                        } else if (status.current.word === status.target.word && status.current.letter > status.target.letter) {
                            letterNext = status.current.letter - 1
                        }
                    }
                }


                let wordNext2 = wordNext !== null ? wordNext : status.current.word
                let letterNext2 = letterNext !== null ? letterNext : status.current.letter

                let direction = null
                let target = null
                let textCurrent = null

                if (status.current.direction === -1
                    && wordNext2 === status.target.word
                    && letterNext2 === status.target.letter) {
                    direction = 1
                    textCurrent = status.textTarget

                    target = {
                        word: status.textTarget.length - 1,
                        letter: status.textTarget[status.textTarget.length - 1].text ? status.textTarget[status.textTarget.length - 1].text.length - 1 : 0
                    }


                }


                /* */

                if (wordNext !== null || letterNext !== null) {
                    /*
                    setCurrent(prev => ({
                        ...prev,
                        letter: letterNext !== null ? letterNext : prev.letter,
                        word: wordNext !== null ? wordNext : prev.word
                    }))
                    */

                    setStatus(prev => ({
                        ...prev,
                        current: {
                            ...prev.current,
                            letter: letterNext !== null ? letterNext : prev.current.letter,
                            word: wordNext !== null ? wordNext : prev.current.word,
                            direction: direction !== null ? direction : prev.current.direction,
                        },
                        target: target !== null ? target : prev.target,
                        textCurrent: textCurrent ? textCurrent : prev.textCurrent,
                        finished: false
                    }))
                } else {

                    setStatus(prev => ({
                        ...prev,
                        finished: true
                    }))

                    //clearInterval(interval.current);
                }
            }, time);
        }

        return () => {
            //clearInterval(interval.current);
            clearTimeout(timeout.current);
        }
    }, [status.current, status.target, status.finished])

    //console.log(status.current)

    const output1 = []
    const output2 = []

    status.textCurrent.slice(0, status.current.word + 1).forEach((word, index) => {
        
        let output 
        
        if (word.tag === 'br') {
            output = <br key={index+'br'}/>
        } else {
            const Tag   = word.tag
                        ? word.tag
                        : 'span'

            const text = index === status.current.word
                ? word.text.slice(0, status.current.letter + 1)
                : word.text

            const key = `${index}${text.replaceAll(' ', '')}`

            if (word.tag === 'NavLink') {

                output = <NavLink to={word.path} key={key} active={word.path.match(`/${main}/${second}`)}>{text}</NavLink>
            } else {
                output = <Tag className={word.className} href={word.path} target={word.target} key={key}>{text}</Tag>
            }
            
        }

        if (word.tag === 'NavLink' || output2.length) {
            output2.push(output)
        } else {
            output1.push(output)
        }
        
    })

    if(output2.length) {
        output2.push(<span className={'cursor' + (status.finished ? ' finished' : '')} key="cursor">|</span>)
    } else {
        output1.push(<span className={'cursor' + (status.finished ? ' finished' : '')} key="cursor">|</span>)
    }

    const onClick = (e) => {

        console.log(e)

        if (e.target.nodeName === 'H1') {
            //setTextTarget(title.concat(navigation))
            //setNavOpen(true);

            setStatus(prev => ({
                ...prev,
                navOpen: !prev.navOpen
            }))
        } else if (e.target.nodeName === 'A') {

            if (!e.target.getAttribute('href').match('http')) {
                e.preventDefault();

                setStatus(prev => ({
                    ...prev,
                    navOpen: false
                }))


                if (!second) {
                    console.log('Ss')
                    scrollTo(e.target.getAttribute('href'))
                } else {
                    history.replace(e.target.getAttribute('href'))
                }
            }



            //console.log(e.target.getAttribute('href'))
        } else if(e.target.classList.contains('menu')) {
            setStatus(prev => ({
                ...prev,
                navOpen: !prev.navOpen
            }))
        }
    }

    return (
        <StyledHeader 
            className={status.navOpen ? 'active' : ''}
            onClick={onClick}>
            <div>
                <span ref={menu}>{output1}</span>
            </div>
            {(output2.length>0) && (
                <navigation>
                    <span ref={menu2}>
                    {output2}
                    </span>
                </navigation>
            )}
        </StyledHeader>
    );
};

const mapStateToProps = (state, props) => {
    const { pages } = state.pages

    const { main, second } = props.match.params;

    let data = pages.find(page => page.path === `/${main}/${second}/`)

    if (!data) {
        data = []
    }

    return {
        pageData: data
    }
}

const mapDispatchToProps = dispatch => ({

})

function propsAreEqual(prevProps, nextProps) {
    console.log(String(nextProps.location.pathname))
    return (
        prevProps.location.pathname === nextProps.location.pathname
        && prevProps.pageData.title === nextProps.pageData.title
        //&& prevProps.scrollTo === nextProps.scrollTo
        && prevProps.data === nextProps.data
    )
    //return false
}

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(memo(Header, propsAreEqual));
