import React, { memo, useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { getPage } from '../../actions'

import Page from './Page';
import Media from './Media';

const fadeIn = keyframes`
    0%  {
        opacity: 0;
    }

    99% {
        position: fixed;
        opacity: 1;
    }

    100% {
        position: relative;
        opacity: 1;
        pointer-events: inherit;
    }
`

const fadeOut = keyframes`
    0%  {
        opacity: 1;
    }

    100% {
        opacity: 0;
       
    }
`

const StyledOverlay = styled.div`
    position: fixed;
    z-index: 99;
    width: 100%;
    min-height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    animation: ${fadeIn} 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
    will-change: opacity, transform; 
    pointer-events: none;
    
    &.out {
        position: fixed;
        animation: ${fadeOut} 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
    }

    &.page {
        padding: 0 var(--paddingPageX);
    }
    
    &.media {
        background: var(--colorLightBlue);
        z-index: 101;
    }

`


let scrollTop = null

const Overlay = ({ data2, active, type, visible, location, history, match, getPage }) => {
    //const [visible, setVisible] = useState(false)
    const [data, setData] = useState(null);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {

        if (data2?.path) {
            setData(data2)
            //window.scroll(0, 0);
        }

    }, [data2])

    useEffect(() => {
        const scroll = () => {

            if (active) {
                setScrollY(window.scrollY)
            }

        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [active])




    const { main, second, third } = match.params;

    useEffect(() => {
        if (second && type==='page') {
            getPage(`/${main}/${second}/`)
        }
    }, [second, type])

    useEffect(() => {
        if (!visible) {
            setData(null)
        }

    }, [visible])

    //if (!visible) return null

    const classes = [type, active ? 'in' : 'out']

    let styleTest = {}

    if (!active) {
        styleTest = { top: `-${scrollY}px` }
    }

    //classes.push('noanimate')

    return (
        <StyledOverlay className={classes.join(' ')} style={styleTest}>
            {(type === 'page' && data) &&
                <Page data={data} match={match} />
            }
            {(type === 'media' && data) &&
                <Media data={data} />
            }
        </StyledOverlay>
    )
};

const mapStateToProps = (state, props) => {
    const { pages } = state.pages

    const { main, second } = props.match.params;

    let data = null 
    
    if(props.type==='page') {
        data = pages.find(page => page.path === `/${main}/${second}/`)
    } else if(props.type==='media') {
        try {
            const page = pages.find(page => page.path === `/`)
            const section = page.data.find(section => section.path === '/medien')
            data = section.data.find(item => item.path === `/${main}/${second}/`)
            console.log(data)
        }
        catch (e) {
            // Anweisungen für jeden Fehler
            //logMyErrors(e); // Fehler-Objekt an die Error-Funktion geben
        }
    }
    
    return {
        data2: data
    }
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path))
})

function propsAreEqual(prev, next) {
    return false

    console.log('cancel render')
}

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(memo(Overlay, propsAreEqual));
