import React, { useRef } from 'react';
import styled from 'styled-components';
import { useIsVisible, useBlackWhiteOnScroll, usePreload } from 'hooks'

const StyledGallery = styled.div`
    position: relative;
    margin: 0 auto;
    padding-bottom: 72.03%;

    > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center top;
        transition: opacity 1.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        opacity: 0;

        &.loaded {
            opacity: 1;
        }
    }
`

const Gallery = (props) => {
    let { data } = props

    data  = data[0];

    const ref = useRef();
    const visible = useIsVisible({ ref })
    const isLoaded = usePreload({ image: data.path, shouldLoad: visible })
    //const [scrollY, setScrollY] = useState(0);

    const bw = useBlackWhiteOnScroll({ref, visible})

    let style = { 
        filter: `grayscale(${bw}%)`, 
        backgroundImage:  
            `linear-gradient(
                rgba(255,255,255,0.3),
                rgba(255,255,255,0.3)
            ),
            linear-gradient(
                ${data.color},
                ${data.color}
            )`
    }

    let style2 = isLoaded ? { backgroundImage: `url(${data.path})` } : {}

    return (
        <StyledGallery ref={ref} style={style} className="filter">
            <div
                style={ style2 }
                className={isLoaded ? 'loaded' : ''}  >
                {data.description &&
                    <p className="copy">{data.description}</p>
                }
            </div>
        </StyledGallery>
    );
};

export default Gallery;