import React from 'react';
import styled from 'styled-components';
import Button from '../button';
import ExpandableList from 'components/ExpandableList';
import constants from 'styles/constants';

const StyledText = styled.div`
    &.small {
        margin: 0 auto;
        max-width: 956px;
    }

    @media (min-width: ${constants.breakpoint.md}px) {
        &.large > div:not(.files) {
            font-size: 1.5625rem;
            line-height: 1.2;
        }
    }

    h4,
    h5,
    h6 {
        font-weight: bold;
    }

    h5,
    h6 {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .files {
        margin-top: 35px;

        > * {
            margin-right: 15px;
        }
    }
`

const Text = ({data}) => {

    const text = <div dangerouslySetInnerHTML={{__html: data.text}} />

    return (
        <StyledText className={data.downloads ? 'large' : 'small'}>
            {data.expandable ? (
                <ExpandableList
                    as="div"
                    more="Datenschutz und Impressum"
                    less="weniger">
                    {text}
                </ExpandableList>
            ) : (
                <>{text}</>
            )}   
            
            {data.downloads?.length && 
                <div className="files">
                    {data.downloads.map((item, index) => (
                        <Button
                            key={index} 
                            text={item.name} 
                            path={item.file} />
                    ))}
                </div>
            }
        </StyledText>
    );
};

export default Text;