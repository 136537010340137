import React from 'react';
import styled from 'styled-components';
import ExpandableList from 'components/ExpandableList';
import constants from 'styles/constants';

const StyledNews = styled.div`
    &,
    h3 {
        font-size: 1.25rem;
        line-height: 1.5;

        @media (min-width: ${constants.breakpoint.md}px) {
            font-size: 1.5625rem;
            line-height: 1.2;
        }
    }  
   
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    h3 {
        font-weight: 700;
    }

    li {
        padding: 30px 0;
    }

    > ul > li:first-child {
        padding-top: 0;
    }

    > ul > li:last-child {
        padding-bottom: 0;
    }
`

const Item = ({ data }) => {
    return (
        <li>
            <h3>{data.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: data.text }} />
        </li>
    )
}

const News = ({ data }) => {
    
    return (
        <StyledNews>
            <ul>
                {data.slice(0, 2).map((item, index) => (
                    <Item data={item} key={index} />
                ))}
            </ul>
            {(data.length > 2) &&
                <ExpandableList
                    more="mehr Aktuelles"
                    less="weniger Aktuelles">
                    {data.slice(2).map((item, index) => (
                         <Item data={item} key={index} />
                    ))}
                </ExpandableList>
            }
        </StyledNews>
    );
};

export default News;