const constants = {
    colors: {
        //lightblue: '#00e1ff'
        lightblue: 'rgb(0, 235, 255)',
        lightblue2: 'rgb(0, 255, 255)'
    },

    breakpoint: {
        xxs: 0,
        xs: 360,
        sm: 576,
        md: 768,
        lg: 1024,
    },
}

export default constants;