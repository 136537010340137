import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIsVisible, useBlackWhiteOnScroll } from '../../hooks'
import ExpandableList from 'components/ExpandableList';
import constants from 'styles/constants';

const StyledTheatre = styled.div`
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style: none;
    }     

    .image {
        padding-bottom: 59.51%;
        background-size: cover;
    }

    h3 {
        margin-top: 22px;
    }

    li {
        position: relative;
        flex: 0 0 100%;
        margin-bottom: 45px;

        @media (min-width: ${constants.breakpoint.sm}px) {
            flex: 0 0 calc(50% - 15px);
        }

        @media (hover: hover) {
            &:hover {
                h3 {
                    color: var(--colorLightBlue);
                }

                .image {
                    opacity: 0.95;
                }
            }
        }
    }

    > div {
        margin: 0 -30px;
        padding: 0 30px;
    }
`

const Item = ({ data }) => {
    const ref = useRef();
    const visible = useIsVisible({ ref })
    const bw = useBlackWhiteOnScroll({ ref, visible })

    console.log('render')

    return (
        <li>
            <Link to={data.path} className="area" />
            {data.image &&
                <div className="image filter" ref={ref} style={{ backgroundImage: `url(${data.image.path})`, filter: `grayscale(${bw}%)` }}>
                    {data.image.caption &&
                        <p className="copy">{data.image.caption}</p>
                    }
                </div>
            }
            <h3>{data.title}</h3>
        </li>
    )
}


const Theatre = ({ data }) => {
    return (
        <StyledTheatre>
            <ul>
                {data.slice(0, 2).map((item, index) => (
                    <Item data={item} key={index} />
                ))}
            </ul>
            {(data.length > 2) &&
                <ExpandableList
                    more="mehr Theater"
                    less="weniger Theater">
                    {data.slice(2).map((item, index) => (
                        <Item data={item} key={index} />
                    ))}
                </ExpandableList>
            }
        </StyledTheatre>
    );
};

export default Theatre;