import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.span`
    color: var(--colorLightBlue);
    cursor: pointer;

    &::before {
        content: '{ ';
    }

    &::after {
        content: ' }';
    }

    @media (hover: hover) {
        &:hover {
            color: #000;
        }
    }
`

const Button = ({ text, path, onClick }) => {

    if (path) {
        return (
            <StyledButton as="a" href={path}>
                {text}
            </StyledButton>
        );
    }

    return (
        <StyledButton onClick={onClick}>
            {text}
        </StyledButton>
    );
};

export default Button;