import React from 'react';
import styled from 'styled-components';
import Column from './Column';
import constants from 'styles/constants';
import { StyledButton } from 'components/button';

const StyledEssay = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto 0 auto;
    max-width: 956px;

    > div {
        flex: 0 0 100%;

        + div {
            margin-top: 30px;
        }
        
        @media (min-width: ${constants.breakpoint.md}px) {
            flex: 0 0 calc(33.33% - 30px);

            + div {
                margin-top: 0px;
            }
        }
    }

    h3 {
        font-size: 1.1875rem;
        line-height: 1.57;
        margin-bottom: 45px;
        text-transform: inherit;
        color: var(--colorLightBlue);
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        margin-bottom: 45px;

        + li:last-child {
            padding-bottom: 45px;
        }
    }

    .media {
        position: relative;
        max-width: 208px;
        width: 100%;
        
        > div {
            position: relative;
            padding-bottom: 100%;
        }

        .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: no-repeat left bottom;
            background-size: contain;
        }

        .cover {
            position: absolute;
            left: 0;
            top: 0;
            width: 70%;
            height: 100%;
            padding: 18px;
            color: var(--colorLightBlue);
            border: 1px solid currentColor;
        }
    }

    h4 {
        font-size: 1.1875rem;
        line-height: 1.57;
        margin-top: 20px;
        text-transform: uppercase;
    }

`

const Essay = ({data}) => {
    return (
        <StyledEssay>
            <Column data={data.filter((item) => item.category === 2)} title={'Zeitgeschehen'} />
            <Column data={data.filter((item) => item.category === 3)} title={'Über Musiktheater'} />
            <Column data={data.filter((item) => item.category === 4)} title={'Über andere Künste'} />
        </StyledEssay>
    );
};

export default Essay;